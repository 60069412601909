






































































































































import { Vue, Component, Ref } from "vue-property-decorator";
import store from "@/store";
//  utilities
import dateHelper from "@/Scripts/utilities/date-helper";
import moment from "moment";
//  types
import { DataQualityHeader, IndicatorData } from "@/types/dataQuality/dataQualityTable";
import { headers } from "./VDataTableHeaders/DataOutliers";
import { DataOutlier, FeatureConfig } from "@/types/dataQuality/dataOutliers";
//  components
import ManageModal from "@/components/dataQuality/OutliersModals/ManageModal.vue";
import HistoricalModal from "@/components/dataQuality/OutliersModals/HistoricalModal.vue";
import ThresholdsModal from "@/components/dataQuality/OutliersModals/ThresholdsModal.vue";
import DataOutliersTable from "@/components/dataQuality/DataOutliersTable/index.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";
import UserModule from "@/store/clients/User.module";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);
const User = getModule(UserModule, store);

@Component({
  components: {
    DataOutliersTable,
    ManageModal,
    HistoricalModal,
    ThresholdsModal,
  },
})
export default class DataOutliers extends Vue {
  @Ref("RefManageModal") RefManageModal!: any;

  searchQuery = "";
  ManageModal = false;
  HistoricalModal = false;
  ThresholdsModal = false;
  isThresholdsModalDataLoading = false;
  isTableReady = false;
  // TODO current dayte and year back
  dates: string[] = [moment().subtract(1, "years").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
  date = moment().format("YYYY-MM-DD");
  menu = false;
  headers: DataQualityHeader[] = headers;
  featureConfigs: FeatureConfig[] = [];

  // @GETTERS
  get isEnvDevelopment(): boolean {
    return process.env.NODE_ENV === "development";
  }

  vesselName(vesselId: number): string | undefined {
    const vesselName = Vessels.extendedVessels.find(vessel => vessel.id === vesselId)?.name;
    if (!vesselName) return;
    return vesselName;
  }

  get loadingState() {
    return DataQuality.loadingState;
  }

  get dateRangeText() {
    return `${moment(this.dates[0]).format("ll")} - ${moment(this.dates[1]).format("ll")}`;
  }

  get dateRange() {
    return `${moment(this.dates[0]).format("DD MMM")} - ${moment(this.dates[1]).format("DD MMM")}`;
  }

  get queryRequest(): { companyId: number | null; fromDate?: string; toDate?: string } {
    return { companyId: User.userCompanyId, fromDate: this.dates[0], toDate: this.dates[1] };
  }

  get userCompanyId(): number | null {
    return User.userCompanyId;
  }

  get outlierList(): { featureName: string; vesselId: number; vesselName: string; outliers: DataOutlier[] }[] {
    return DataQuality.companyOutliers
      .map(item => {
        const vessel = Vessels.extendedVessels.find(v => v.id === item.vesselId);
        return item.data.map(indicator => {
          return {
            vesselId: item.vesselId,
            vesselName: vessel ? vessel.name : "Unknown Vessel",
            featureName: indicator.featureName,
            outliers: indicator.outliers || [],
          };
        });
      })
      .flat();
  }

  get uniqueFeatureNames(): string[] {
    const featureNames = this.outlierList.map(item => item.featureName);
    return Array.from(new Set(featureNames));
  }

  get rows(): any {
    var rows = DataQuality.companyOutliers.map((item, index) => {
      const vesselName = this.vesselName(item.vesselId);
      const indicators = item.data.reduce((acc: any, indicator) => {
        acc[indicator.featureName.replace(/\s/g, "")] = {
          value: indicator.value,
          link: null,
        };
        return acc;
      }, {});
      return {
        key: index,
        vesselId: item.vesselId,
        vesselName: {
          value: vesselName ?? "Uknown Vessel",
          link: vesselName ? `/Vessel/${item.vesselId}/${encodeURIComponent(vesselName)}` : null,
        },
        overallStatus: {
          value: item.overallStatus,
          link: null,
        },
        ...indicators,
        settings: {
          value: null,
          link: null,
        },
      };
    });
    if (this.searchQuery && this.searchQuery.trim()) {
      const query = this.searchQuery.toLowerCase();
      return rows.filter(item => item.vesselName.value.toLowerCase().includes(query));
    }

    return rows;
  }
  // @METHODS
  async setDateRange(date: string): Promise<void> {
    (this.$refs.datepickerMenu as any).save(date);
    await DataQuality.fetchCompanyOutliers(this.queryRequest);
  }

  async openThresholdsModal(vesselId: string): Promise<void> {
    this.isThresholdsModalDataLoading = true;
    this.ThresholdsModal = true;
    this.featureConfigs = await DataQuality.fetchFeatureConfigs(vesselId);
    if (this.featureConfigs.length === 0) {
      this.featureConfigs = this.uniqueFeatureNames.map(name => {
        return {
          featureName: name,
          vesselId: Number(vesselId),
          lowerBound: 0,
          upperBound: 0,
        };
      });
    }
    this.isThresholdsModalDataLoading = false;
  }

  showOutliersInManageModal(indicatorData: IndicatorData): void {
    this.RefManageModal.setSelectedOutliers({ feature: indicatorData.column, vesselId: indicatorData.vesselId });
  }

  async updateFeatureConfigs(featureConfigs: FeatureConfig[]) {
    await DataQuality.updateFeatureConfigs(featureConfigs);
  }

  async onOutlierStateChanged(): Promise<void> {
    await DataQuality.fetchCompanyOutliers(this.queryRequest);
  }
  // @HOOKS
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.fetchCompanyOutliers(this.queryRequest), DataQuality.fetchFeatureValues()]);
    const dataIndicatorHeaders = DataQuality.companyOutliers[0].data.map(indicator => ({
      text: indicator.featureName,
      type: "percentIndicator",
      value: indicator.featureName.replace(/\s/g, ""),
      width: "140px",
      align: "center",
      class: "text-center",
      sortable: false,
    }));
    //  add settings column
    dataIndicatorHeaders.push({
      text: "",
      type: "settings",
      align: "center",
      value: "settings",
      class: "text-center",
      sortable: false,
      width: "65px",
    });
    this.headers = [...this.headers, ...dataIndicatorHeaders];
    this.isTableReady = true;
  }
}
