













































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/Vessel";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import DataQualityModule from "@/store/clients/DataQuality.module";

const DataQuality = getModule(DataQualityModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {},
})
export default class HistoricalOutliersTable extends Vue {
  @PropSync("selected", { type: Array, default: () => [] }) selectedRows!: Object[];
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) selection!: Object[];
  @Prop({ type: Array, default: () => [] }) headers!: Object[];
  @Prop({ type: Array, default: () => [] }) tagsList!: Object[];
  @Prop({ type: Array, default: () => [] }) vesselIds!: Object[];

  item: any = {};
  filterByVessels: ExtendedVessel[] = [];
  filterByTags: any[] = [];
  pageLimit = 10;

  //  @Getters
  get vessels(): ExtendedVessel[] {
    return Vessels.extendedVessels.filter(vessel => this.vesselIds.includes(vessel.id));
  }

  get isLessThanLimit(): boolean {
    return this.selection.length <= this.pageLimit;
  }

  get dataTagList(): any[] {
    return this.tagsList;
  }
  //  @Methods
  featureUnit(featureName: string): string {
    return DataQuality.featureValues.find(feature => feature.name === featureName)?.unit.caption || "n/a";
  }

  clearSelection(): void {
    this.filterByVessels = [];
  }

  actionClasses(action: string): any[] {
    return [{ "action-span": action !== "N/A" }, { acknowledged: action === "Acknowledged" }, { deleted: action === "Deleted" }];
  }
}
