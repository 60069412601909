



















































import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import store from "@/store";
import dateHelper from "Utilities/date-helper";
//  types
import { HistoricalOutliersRow } from "@/types/dataQuality/HistoricalOutliersRow";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
import { DataOutlier } from "@/types/dataQuality/dataOutliers";
import { UserProfile } from "@/types/userProfile";
//  components
import HistoricalOutliersTable from "@/components/dataQuality/HistoricalOutliersTable.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";
import UsersModule from "@/store/clients/Users.module";
import SnackbarModule from "@/store/clients/Snackbar.module";

const DataQuality = getModule(DataQualityModule, store);
const Vessels = getModule(VesselsModule, store);
const Users = getModule(UsersModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  components: {
    HistoricalOutliersTable,
  },
})
export default class HistoricalModal extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @Prop({ type: String, default: "1290px" }) width!: string;
  @Prop() data!: { featureName: string; vesselId: number; vesselName: string; outliers: DataOutlier[] }[];

  selectedRows: HistoricalOutliersRow[] = [];
  selectedColumn = "";
  searchTerm = "";

  //  @Getters
  get loading(): boolean {
    return DataQuality.modalLoadingState;
  }

  get headers(): DataQualityHeader[] {
    return [
      { text: "Vessel", value: "vesselName", sortable: false },
      { text: "Feature", value: "featureName", sortable: false },
      { text: "Outlier Value", value: "value" },
      { text: "Date & Time", value: "date" },
      { text: "Action", value: "action" },
      { text: "Performed by", value: "userName" },
      { text: "Action date", value: "actionDate" },
    ];
  }

  get rows(): HistoricalOutliersRow[] {
    return this.data.flatMap(item =>
      item.outliers
        .map(outlier => {
          const userPerformedAction = this.users.find(u => {
            if (!outlier.auditLogs || outlier.auditLogs.length === 0) return;
            return outlier.auditLogs[outlier.auditLogs.length - 1].userId === u.id;
          });
          return {
            id: outlier.id,
            action: outlier.auditLogs && outlier.auditLogs.length ? outlier.auditLogs[outlier.auditLogs.length - 1].action : "N/A",
            vesselName: item.vesselName,
            featureName: item.featureName,
            value: Number(outlier.value).toFixed(6),
            timestamp: outlier.timestamp,
            date: `${this.getFormatedDateTimeString(outlier.timestamp)} UTC`,
            isDeleted: outlier.isDeleted,
            isAcknowledged: outlier.isAcknowledged,
            userName: userPerformedAction ? userPerformedAction.name : "N/A",
            actionDate: outlier.auditLogs && outlier.auditLogs.length ? `${this.getFormatedDateTimeString(outlier.auditLogs[outlier.auditLogs.length - 1].timestamp)} UTC` : "N/A",
          };
        })
        .filter(outlier => outlier.isAcknowledged === true || outlier.isDeleted === true)
    );
  }

  get vesselIds(): number[] {
    const vesselIds = this.data.filter(item => item.outliers.length > 0).map(item => item.vesselId);
    return Array.from(new Set(vesselIds));
  }

  get tagsList(): string[] {
    const featureNames = this.rows.map(row => row.featureName);
    return Array.from(new Set(featureNames));
  }

  get isDisabled(): boolean {
    return this.selectedRows.length === 0;
  }

  get users(): UserProfile[] {
    return Users.users;
  }

  // @Methods
  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.$emit("close");
  }

  async updateOutliers(): Promise<void> {
    const acknowledgedOutliers = this.selectedRows.map(row => {
      return {
        id: row.id,
        isAcknowledged: false,
        isDeleted: false,
        value: Number(row.value),
        timestamp: row.timestamp,
      };
    });
    await DataQuality.updateOutliersAcknowledge(acknowledgedOutliers);
    await DataQuality.updateOutliersRemove(acknowledgedOutliers);
    this.$emit("updateOutliers");
    this.selectedRows = [];
    Snackbar.showSnackbar({ text: "Outlier(s) status successfully updated", color: "success" });
    this.close();
  }

  getFormatedDateTimeString(timestamp: string): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  //  @Hooks
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), Users.refreshUsers()]);
  }
}
