


















































import { Component, Prop, Vue } from "vue-property-decorator";
//  utilities
import dateHelper from "Utilities/date-helper";
import moment from "moment";
//  types
import { IndicatorData } from "@/types/dataQuality/dataQualityTable";

@Component({})
export default class DataIndicator extends Vue {
  @Prop({ type: Object }) data!: IndicatorData;

  //  @Getters
  get isNotAvailable(): boolean {
    return this.data.status === null;
  }

  get statusColor() {
    if (!this.data) return;
    if (this.data.status === 0) return "success100";
    else if (Number(this.data.status) > 0 && Number(this.data.status) <= 10) return "warning100";
    else if (Number(this.data.status) > 10) return "error100";
    else return "darkGrey100";
  }

  get outlierValueClasses() {
    if (!this.data) return;
    if (this.data.status === 0) return "no-outliers";
    else if (Number(this.data.status) > 0 && Number(this.data.status) <= 10) return "minor";
    else if (Number(this.data.status) > 10) return "major";
    else return "not-available";
  }

  //  @Methods
  navigateToLink(): void {
    if (!this.data.link) throw new Error("Link is undefined");
    this.$router.push(this.data.link);
  }

  getFormatedDateTimeString(timestamp: string): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  duration(timestamp: string): string {
    return dateHelper.getHumanizeDuration(timestamp, moment().valueOf());
  }

  showOutliersInManageModal(): void {
    if (this.outlierValueClasses === "no-outliers" || this.outlierValueClasses === "not-available") return;
    this.$emit("showOutliersInManageModal", this.data);
  }
}
