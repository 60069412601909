

























































































import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ManageOutliersRow } from "@/types/dataQuality/ManageOutliersRow";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
import { FeatureConfig } from "@/types/dataQuality/dataOutliers";
//  components
import ManageOutliersTable from "@/components/dataQuality/ManageOutliersTable.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";

const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {},
})
export default class ThresholdsModal extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @Prop({ type: String, default: "448px" }) width!: string;
  @Prop() readonly isDataLoading!: boolean;
  @Prop({ type: Array, default: () => [] }) featureConfigs!: FeatureConfig[];

  featureInputDirtyList: string[] = [];

  //  @Getters

  // @Methods
  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.$emit("close");
  }

  onThresholdChanged(featureName: string): void {
    if (!this.featureInputDirtyList.includes(featureName)) this.featureInputDirtyList.push(featureName);
  }

  featureUnit(featureName: string): string {
    return DataQuality.featureValues.find(feature => feature.name === featureName)?.unit.caption || "n/a";
  }

  updateFeatureConfigs(): void {
    this.$emit("updateFeatureConfigs", this.featureConfigs);
    this.close();
  }

  //  @Hooks
}
